import _ from "lodash";
export var isEmpty = _.isEmpty;
// return true if query in features, false otherwise
export var featureEnabled = function (features, where) {
    var or = where.or, and = where.and, not = where.not;
    // first check if we have a disable condition
    var notValue = function (not_) {
        if (Array.isArray(not_)) {
            return not_.some(function (q) { return featureEquals(features, q); });
        }
        return featureEquals(features, not_);
    };
    if (not && notValue(not))
        return false;
    var andValue = function (and_) {
        if (Array.isArray(and_)) {
            return and_.every(function (q) { return featureEquals(features, q); });
        }
        return featureEquals(features, and_);
    };
    // optionally enforce a combination of features
    if (and && !andValue(and))
        return false;
    // if "and" or "not" was supplied and "or" was not, return true since there's nothing to check for "or"
    if ((and || not) && (!or || !or.length))
        return true;
    // check our "or" clause
    var orValue = function (or_) {
        if (Array.isArray(or_)) {
            return or_.some(function (q) { return featureEquals(features, q); });
        }
        return featureEquals(features, or_);
    };
    return !!(or && orValue(or));
};
export var featureEquals = function (features, flag, setting) {
    if (flag === undefined)
        return false;
    // if flag is not in features
    if (!Object.hasOwnProperty.call(features, flag))
        return false;
    var feature = features[flag];
    var isObject = typeof feature === "object";
    // feature is in object but is undefined (this shouldn't happen)
    if (feature === undefined)
        return false;
    // setting is not provided, return false if feature is null, false, [], "", {}
    // this matches the truthiness rules of python
    if (setting === undefined)
        return !([null, false, "", {}].includes(feature) ||
            (Array.isArray(feature) && feature.length === 0) ||
            (isObject && _.isEmpty(feature)));
    // setting is an array, check if any of the values in the array match
    if (Array.isArray(setting))
        return setting.some(function (s) { return featureEquals(features, flag, s); });
    // feature is an array, check if setting is in array
    if (Array.isArray(feature))
        return feature.includes(setting);
    return feature === setting;
};
export default function Feature(props) {
    var or = props.or || [];
    if (props.flag)
        or.push(props.flag);
    var feature = featureEnabled(props.features, {
        or: or,
        and: props.and,
        not: props.not,
    });
    if (props.flag && props.equals) {
        // handle equals property
        if (!featureEquals(props.features, props.flag, props.equals)) {
            feature = false;
        }
    }
    if (!feature && !props.fallback) {
        return null;
    }
    if (!feature && props.fallback) {
        return props.fallback;
    }
    return props.children;
}
