import ApiHttp from "./ApiHttp";

export default class Organization {
  constructor(props) {
    this.uuid = props.uuid;
    this.dual_approval_required = props.dual_approval_required;
    this.dual_approval_user_control = props.dual_approval_user_control;
  }

  static dualApprovalRequired(orgUuid, method = "GET", value = undefined) {
    return ApiHttp.fetch(
      `organizations/${orgUuid}/dual_approval_required`,
      {
        method,
      },
      {
        value,
      },
    ).then((res) => {
      return Organization.deserialize(res.organization);
    });
  }

  static getNumberOfDualApprovalRequests() {
    return ApiHttp.fetch(`approval_requests`, {
      method: "GET",
    }).then((res) => res.meta.total);
  }

  /*
   *  Factory method returning a new instance of Organization from
   *  an ApiHttp serialized Organization
   */
  static deserialize(payload) {
    return new Organization({
      uuid: payload.uuid,
      dual_approval_required: payload.dual_approval_required,
      dual_approval_user_control: payload.dual_approval_user_control,
    });
  }

  serialize() {
    return {
      uuid: this.uuid,
      dual_approval_required: this.dual_approval_required,
      dual_approval_user_control: this.dual_approval_user_control,
    };
  }
}
