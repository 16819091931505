import { RRule } from "rrule";
/* moment can compute locale-specific ordinal strings (e.g. 1st, 2nd, 3rd)
 * so cannot be deprecated until an alternate solution is found
 */
import moment from "moment";
import { DateTime } from "luxon";

import Filters from "./filters";

export const FREQUENCIES = Object.freeze({
  ONCE: "Once",
  DAILY: "Daily",
  WEEKLY: "Weekly",
  EVERY_TWO_WEEKS: "Every two weeks",
  FIRST_AND_FIFTHTEENTH: "1st and 15th",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
});

export const generateRecurrenceRuleString = (frequency, dateString) => {
  if (!dateString) return "";
  const date = DateTime.fromFormat(dateString, "yyyy-MM-dd"); // dateString must be of this format
  const dtstart = `DTSTART:${date.toFormat("yyyyMMdd")}\n`;

  if (frequency === FREQUENCIES.ONCE) {
    // if for today, just do it, no rule needed.
    if (date.hasSame(DateTime.now(), "day")) return "";
    // schedule it daily but only one time, at the date specified.
    return `${dtstart}RRULE:FREQ=DAILY;COUNT=1`;
  }
  if (frequency === FREQUENCIES.DAILY) {
    const rule = new RRule({
      freq: RRule.DAILY,
      interval: 1,
    });
    return `${dtstart}${rule.toString()}`;
  }
  if (frequency === FREQUENCIES.WEEKLY) {
    const rule = new RRule({
      freq: RRule.WEEKLY,
      interval: 1,
    });
    return `${dtstart}${rule.toString()}`;
  }
  if (frequency === FREQUENCIES.MONTHLY) {
    const byMonthDay =
      date.day > 28
        ? Array.from(Array(date.day - 28 + 1), (e, i) => i + 28)
        : date.day;
    const rule = new RRule({
      freq: RRule.MONTHLY,
      bymonthday: byMonthDay,
      bysetpos: -1,
    });
    return `${dtstart}${rule.toString()}`;
  }
  if (frequency === FREQUENCIES.EVERY_TWO_WEEKS) {
    const rule = new RRule({
      freq: RRule.WEEKLY,
      interval: 2,
    });
    return `${dtstart}${rule.toString()}`;
  }
  if (frequency === FREQUENCIES.FIRST_AND_FIFTHTEENTH) {
    const rule = new RRule({
      freq: RRule.MONTHLY,
      bymonthday: [1, 15],
    });
    return `${dtstart}${rule.toString()}`;
  }
  if (frequency === FREQUENCIES.YEARLY) {
    const rule = new RRule({
      freq: RRule.YEARLY,
      interval: 1,
    });
    return `${dtstart}${rule.toString()}`;
  }
  return "";
};

export const generateFrequencyString = (rrule) => {
  /* generates the English translation of the recurring rule's frequency */
  const { options } = rrule;
  const { bymonthday, bynmonthday, bysetpos, count, freq, interval } = options;

  if (count === 1) return FREQUENCIES.ONCE;
  if (freq === RRule.DAILY && interval === 1) return FREQUENCIES.DAILY;
  if (freq === RRule.WEEKLY) {
    if (interval === 1) return FREQUENCIES.WEEKLY;
    if (interval === 2) return FREQUENCIES.EVERY_TWO_WEEKS;
  }
  if (freq === RRule.MONTHLY && interval === 1) {
    /* ensure bysetpos is in ascending order */
    if (bysetpos) {
      bysetpos.sort((a, b) => {
        return a - b;
      });
    }
    /* handles monthly transfers occurring between 29th and 31st */
    if (bysetpos && bysetpos.length === 1 && bysetpos[0] === -1)
      return FREQUENCIES.MONTHLY;
    /* handles monthly transfers occurring before the 29th */
    if (bymonthday.length === 1 && bynmonthday.length === 0)
      return FREQUENCIES.MONTHLY;
    /* handles semi-monthly 1st/15th transfers */
    if (JSON.stringify(bymonthday) === "[1,15]")
      return FREQUENCIES.FIRST_AND_FIFTHTEENTH;
    /* handles semi-monthly */
    if (bysetpos && bysetpos.length === 2) {
      /* handles dates not at the end of the month */
      const day1 = moment.localeData().ordinal(bymonthday[0]);
      if (bymonthday.length === 2) {
        const day2 = moment.localeData().ordinal(bymonthday[1]);
        return `${day1} and ${day2}`;
      }
      /* handles dates with mid month and end of month */
      const string_bysetpos = JSON.stringify(bysetpos);
      const max_monthday = Math.max(...bymonthday);
      const last_day =
        max_monthday < 31
          ? moment.localeData().ordinal(max_monthday)
          : "last day";
      if (string_bysetpos === "[-1,1]" && bymonthday.length > 2) {
        /* since bysetpos include -1, any bymonthday greater than or equal to 31 will be end of month for rrule */
        return `${day1} and ${last_day}`;
      }
      if (
        string_bysetpos === "[-1,1]" &&
        bymonthday.length === 1 &&
        JSON.stringify(bynmonthday) === "[-1]"
      ) {
        /* in case there is a -1 in bynmonthday which represents last day of the month */
        return `${day1} and last day`;
      }
    }
  }
  if (freq === RRule.YEARLY && interval === 1) return FREQUENCIES.YEARLY;

  /* if the rrule fits none of the above criteria, just return rrule's textual representation of the rule */

  return Filters.humanize(rrule.toText());
};

const Recurrence = {
  FREQUENCIES,
  generateFrequencyString,
  generateRecurrenceRuleString,
};

export default Recurrence;
