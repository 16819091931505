// NOTE: Cannot be used by clients without window global
/* eslint-disable no-undef */
import ApiHttp from "./ApiHttp";

const functions = {
  async deleteRule(initialSourceUuid, initialDestinationUuid) {
    await ApiHttp.fetch(
      "automatic_savings_enrollment",
      {
        method: "DELETE",
      },
      {
        source_account_uuid: initialSourceUuid,
        destination_account_uuid: initialDestinationUuid,
      },
    );
    window.location.assign(
      "/automatic?success=Your+automatic+savings+rule+was+deleted.",
    );
  },

  async saveRule(sourceUuid, destinationUuid, toastCopy) {
    await ApiHttp.fetch(
      "automatic_savings_enrollment",
      {
        method: "POST",
      },
      {
        source_account_uuid: sourceUuid,
        destination_account_uuid: destinationUuid,
      },
    );
    window.location.assign(
      `/automatic?success=${toastCopy.replace(/ /g, "+")}`,
    );
  },
};

export default functions;
