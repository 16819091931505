import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import { ChevronUp, ChevronDown } from "react-feather";
import { useLocalization } from "@fluent/react";
import { translateFaqQuestions } from "byzantine/src/l10n/SettingLocalizers";

function FAQQuestion({ question, answer }) {
  return (
    <div>
      <div className="faq-question-text">{question}</div>
      <div className="faq-answer-text">
        <ReactMarkdown linkTarget="_blank">{answer}</ReactMarkdown>
      </div>
    </div>
  );
}
FAQQuestion.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

function FAQCategory({ category_name, faqs }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="faq-category">
      <div
        role="button"
        tabIndex="0"
        className="faq-category-header"
        onKeyUp={({ key }) => {
          if (key === "Enter" && isOpen) {
            setIsOpen(!isOpen);
          }
        }}
        onClick={() => {
          setIsOpen((open) => !open);
        }}
      >
        <div className="card-title">{category_name}</div>
        <div>{isOpen ? <ChevronUp /> : <ChevronDown />}</div>
      </div>
      {isOpen ? (
        <div>
          {faqs.map((faq) => (
            <FAQQuestion
              key={faq.question}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}
FAQCategory.propTypes = {
  category_name: PropTypes.string.isRequired,
  faqs: PropTypes.array.isRequired,
  toggled: PropTypes.bool.isRequired,
  onToggled: PropTypes.func.isRequired,
};

export default function FAQList({ categories }) {
  const { l10n } = useLocalization();
  const translatedCategories = translateFaqQuestions(categories, l10n);
  return (
    <div className="faq-list">
      <div className="support-section-title">{l10n.getString("heading-faq")}</div>
      <div className="card">
        {translatedCategories.map((faqCategory) => (
          <FAQCategory
            key={faqCategory.category}
            category_name={faqCategory.category}
            faqs={faqCategory.questions}
          />
        ))}
      </div>
    </div>
  );
}
FAQList.propTypes = {
  categories: PropTypes.array.isRequired,
};
