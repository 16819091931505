import pkceChallenge from "pkce-challenge";

import ApiHttp from "./ApiHttp";
import utils from "./utils";

export default class App {
  constructor(props) {
    this.id = props.id;
    this.client_id = props.client_id;
    this.name = props.name;
    this.description = props.description;
    this.image = props.image;
    this.is_public = props.is_public;
    this.scopes = props.scopes;
    this.has_own_layout = props.has_own_layout;
  }

  getToken(code, code_verifier) {
    const options = {
      method: "POST",
    };
    const payload = {
      code,
      grant_type: "authorization_code",
      client_id: this.client_id,
      code_verifier,
      scope: this.scopes ? Object.keys(this.scopes).join(" ") : undefined,
    };
    return ApiHttp.fetch("tokens", options, payload).then((response) => {
      return {
        token: response.token,
        code,
      };
    });
  }

  getAuthorization() {
    // these names determined by oauth spec
    return pkceChallenge().then(({ code_verifier, code_challenge }) =>
      ApiHttp.fetch(
        `apps/authorize?client_id=${this.client_id}&code_challenge=${code_challenge}&code_challenge_method=S256`,
      ).then((response) => {
        return this.getToken(response.authorization.code, code_verifier);
      }),
    );
  }

  createAuthorization() {
    return pkceChallenge().then(({ code_verifier, code_challenge }) =>
      ApiHttp.fetch(
        "apps/authorize",
        { method: "POST" },
        {
          client_id: this.client_id,
          code_challenge,
          code_challenge_method: "S256",
        },
      ).then((response) => {
        return this.getToken(response.authorization.code, code_verifier);
      }),
    );
  }

  getNdsIconClassname() {
    const mapMobileIconsToWebIcons = (icon) => {
      switch (icon) {
        case "add_circle":
          return "plus-circle";
        case "insert_chart":
          return "bar-chart-2";
        case "stars":
          return "star";
        case "add_alert":
          return "bell";
        case "AddBox":
          return "plus-square";
        case "Group":
          return "group";
        default:
          return icon;
      }
    };
    const baseIcon = this.image ? mapMobileIconsToWebIcons(this.image) : "star";
    return utils.ndsIconPrepend(baseIcon);
  }

  /**
   * Factory method returning a new instance of App from
   * an indigo.serialized App
   * */
  static deserialize(payload) {
    return new App({
      id: payload.uuid,
      ...payload,
    });
  }
}
