import * as Yup from "yup";
import { createForm } from "../../../forms";
import finance from "../../../../../finance";
var validationSchema = Yup.object().shape({
    accountNumber: Yup.string().required("Required"),
    routingNumber: Yup.string()
        .required("Required")
        .test("length", "Please enter a valid 9-digit routing number.", function (val) { return val.length === 9; })
        .test("valid", "Please enter a valid 9-digit routing number.", function (val) {
        var isValid = finance.abaRoutingNumberIsValid(val);
        return isValid;
    }),
    accountType: Yup.string().required("Required"),
});
var initialValues = {
    accountNumber: "",
    routingNumber: "",
    accountType: "",
};
// define form schema and validations
export var ACHPaymentDetailForm = createForm({
    initialValues: initialValues,
    validationSchema: validationSchema,
});
