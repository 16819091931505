import { jsx as _jsx } from "react/jsx-runtime";
import { SearchSelector } from "cerulean";
var RecipientSelector = function (_a) {
    var value = _a.value, recipients = _a.recipients, label = _a.label, onChange = _a.onChange, onAddRecipientClick = _a.onAddRecipientClick, error = _a.error;
    return (_jsx(SearchSelector, { value: value, items: recipients, label: label, onChange: onChange, filter: function (recipient, searchTerm) {
            return recipient.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
        }, getItemLabel: function (recipient) { return recipient.name; }, getItemValue: function (recipient) { return recipient.id; }, bottomAction: {
            label: "+ Add a new recipient",
            onClick: onAddRecipientClick,
        }, error: error }));
};
export default RecipientSelector;
