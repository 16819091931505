var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMarketingImage } from "byzantine";
import { useState } from "react";
export default function MarketingImageTargetWeb(_a) {
    var address = _a.address, 
    // onImageLoad is called when the image successfully loads.
    onImageLoad = _a.onImageLoad, 
    // onImageError is called if the image does not load. Note that this may be part of normal operation,
    // for example if there is no ad to serve.
    onImageError = _a.onImageError, 
    // debugSize is used for demo and testing purposes. This will return with a placeholder image
    // to make testing easier.
    debugSize = _a.debugSize;
    var _b = __read(useState(false), 2), isError = _b[0], setIsError = _b[1];
    var _c = useMarketingImage(address, debugSize), linkUrl = _c.linkUrl, imageUrl = _c.imageUrl, segmintIframeUrl = _c.segmintIframeUrl;
    if (isError) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx("iframe", { src: segmintIframeUrl, style: { width: 0, height: 0, border: 0, position: "absolute" } }), _jsx("a", { href: linkUrl, target: "_blank", rel: "noopener noreferrer", children: _jsx("img", { src: imageUrl, onLoad: onImageLoad, onError: function () {
                        setIsError(true);
                        onImageError === null || onImageError === void 0 ? void 0 : onImageError();
                    }, 
                    // TODO: this may become dynamic in the future
                    alt: "Promotional image" }) })] }));
}
