import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { useLocalization } from "@fluent/react";
import { Row, Tag } from "cerulean";
import { useBreakpoints } from "@narmi/design_system";
import styles from "./HeaderWithSteps.module.scss";
var HeaderWithSteps = function (_a) {
    var headerText = _a.headerText, subheaderText = _a.subheaderText, step = _a.step, totalSteps = _a.totalSteps, icon = _a.icon;
    var l10n = useLocalization().l10n;
    var s = useBreakpoints().s;
    // on extra small screens, we render the step number above the header, rather than to it's right
    var shouldRenderMobileSteps = !s;
    var renderedStep = useMemo(function () {
        return step && totalSteps ? (_jsx(Tag, { label: l10n.getString("step-number", {
                current: step,
                total: totalSteps,
            }) })) : null;
    }, [l10n, step, totalSteps]);
    return (_jsxs("div", { className: styles.container, children: [_jsxs(Row, { alignItems: "center", children: [_jsxs(Row.Item, { children: [shouldRenderMobileSteps && step && totalSteps && (_jsx("div", { className: "margin--bottom--l", children: renderedStep })), _jsxs("h4", { children: [headerText, icon ? _jsx("span", { className: styles.icon, children: icon }) : null] })] }), !shouldRenderMobileSteps && step && (_jsx(Row.Item, { shrink: true, children: renderedStep }))] }), subheaderText && (_jsx("div", { className: styles.subheaderText, children: subheaderText }))] }));
};
export default HeaderWithSteps;
