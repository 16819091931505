var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ProgressButtons, Tooltip, Select, RoutingNumberTextInput, AccountNumberTextInput, Button, ResponsiveFlex, Row, } from "cerulean";
import { entities } from "byzantine";
import { useLocalization } from "@fluent/react";
import HeaderWithSteps from "../../HeaderWithSteps";
import { useSudoContext } from "../../SudoContext";
import * as formAdapters from "../../../src/adapters";
import RecipientSelector from "../RecipientSelector";
var RecipientSection = function () {
    var form = entities.recipients.useACHPaymentRecipientForm().form;
    var recipients = entities.recipients.useRecipients();
    return (_jsx(RecipientSelector, __assign({ recipients: recipients, field: "recipientId", label: "Recipient", onAddRecipientClick: function () {
            // Add a navigate to the add recipient screen in the future
        } }, formAdapters.fieldWithOnChange(form, "recipient"))));
};
var BankDetailsSection = function () {
    var l10n = useLocalization().l10n;
    var accountTypes = entities.recipients.useAccountTypeOptions();
    var form = entities.recipients.useACHPaymentDetailForm().form;
    return (_jsxs(ResponsiveFlex, { gapSize: "m", children: [_jsxs(Row, { children: [_jsx(Row.Item, { children: _jsx("h4", { className: "fontSize--m nds-sans", children: l10n.getString("ach-payment-bank-title") }) }), _jsx(Row.Item, { shrink: true, children: _jsx(Button, { label: l10n.getString("ach-payment-copy-wire-cta"), kind: "plain", size: "s" }) })] }), _jsxs("div", { className: "message-content info", children: [_jsx("span", { className: "narmi-icon-info margin--right--m" }), l10n.getString("ach-payment-ach-details-banner")] }), _jsx(AccountNumberTextInput, __assign({ label: l10n.getString("labelAccountNumber", null, "Account number") }, formAdapters.fieldWithOnChange(form, "accountNumber"))), _jsx(RoutingNumberTextInput, __assign({ label: l10n.getString("label-routing", null, "Routing number") }, formAdapters.fieldWithOnChange(form, "routingNumber"))), _jsx(Select, __assign({ label: l10n.getString("ach-payment-account-type", null, "Account type") }, formAdapters.select(form, "accountType"), { children: accountTypes.map(function (option, index) { return (_jsx(Select.Item, { value: option, children: option }, "account_type_".concat(index))); }) }))] }));
};
var RecipientScreen = function (_a) {
    var navigateToScreen = _a.navigateToScreen;
    var l10n = useLocalization().l10n;
    var navigate = useNavigate();
    var _b = useSudoContext(), totalSteps = _b.totalSteps, useSetInitialSteps = _b.useSetInitialSteps;
    var paymentDetailsForm = entities.recipients.useACHPaymentDetailForm().form;
    var selectedRecipient = entities.recipients.useSelectedRecipient();
    var onSubmitSuccessful = useCallback(function () {
        navigateToScreen("payment");
    }, [navigateToScreen]);
    var handleSubmit = entities.recipients.useHandleRecipientPageSubmit({
        onSubmitSuccessful: onSubmitSuccessful,
    });
    // Logic specific to the Web recipient screen
    var showBankDetailsSection = useMemo(function () { return selectedRecipient && entities.isWireRecipient(selectedRecipient); }, [selectedRecipient]);
    if (!showBankDetailsSection &&
        !entities.isWireRecipient(selectedRecipient) &&
        paymentDetailsForm.dirty) {
        // Reset the payment details current recipient is not a wire recipient
        paymentDetailsForm.handleReset(paymentDetailsForm.initialValues);
    }
    useSetInitialSteps(3);
    return (_jsxs(ResponsiveFlex, { gapSize: "xl", children: [_jsx(HeaderWithSteps, { headerText: l10n.getString("ach-payment-payment-title"), step: 1, totalSteps: totalSteps, icon: _jsx(Tooltip, { text: l10n.getString("ach-payment-payment-title-tooltip"), children: _jsx("i", { className: "narmi-icon-info", style: {
                            fontSize: "var(--font-size-s)",
                        } }) }) }), _jsx("h4", { children: l10n.getString("ach-payment-recipient-title") }), _jsx(RecipientSection, {}), showBankDetailsSection ? _jsx(BankDetailsSection, {}) : null, _jsx(ProgressButtons, { backLabel: l10n.getString("button-cancel"), nextLabel: l10n.getString("button-continue"), onBack: function () {
                    navigate("/transfers");
                }, onNext: handleSubmit })] }));
};
export default RecipientScreen;
