const functions = {
  // Based on https://github.com/DrShaffopolis/bank-routing-number-validator
  abaRoutingNumberIsValid(string) {
    if (!string || Number(string) === 0) {
      // if it's falsey, it cannot be a routing number. Technically, 000000000
      // is a valid routing number, but it's inactive.
      return false;
    }

    let routing = string.toString();
    while (routing.length < 9) {
      routing = `0${routing}`;
    }

    // must be exactly 9 digits
    const match = routing.match("^\\d{9}$");
    if (!match) {
      return false;
    }

    // The first two digits of the nine digit RTN must be in the ranges 00
    // through 12, 21 through 32, 61 through 72, or 80. Ref:
    // https://en.wikipedia.org/wiki/Routing_transit_number
    const firstTwo = parseInt(routing.substring(0, 2), 10);
    const firstTwoValid =
      (firstTwo >= 0 && firstTwo <= 12) ||
      (firstTwo >= 21 && firstTwo <= 32) ||
      (firstTwo >= 61 && firstTwo <= 72) ||
      firstTwo === 80;
    if (!firstTwoValid) {
      return false;
    }

    // Calculate the checksum, ref:
    // http://www.siccolo.com/Articles/SQLScripts/how-to-create-sql-to-calculate-routing-check-digit.html
    const weights = [3, 7, 1];
    let sum = 0;
    for (let i = 0; i < 8; i += 1) {
      sum += parseInt(routing[i], 10) * weights[i % 3];
    }

    return (10 - (sum % 10)) % 10 === parseInt(routing[8], 10);
  },
};

export default functions;
