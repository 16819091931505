import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchSelector } from "cerulean";
var RecipientSelector = function (_a) {
    var value = _a.value, recipients = _a.recipients, label = _a.label, onChange = _a.onChange, error = _a.error;
    var navigate = useNavigate();
    var location = useLocation();
    return (_jsx(SearchSelector, { value: value, items: recipients, label: label, onChange: onChange, filter: function (recipient, searchTerm) {
            return recipient.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
        }, getItemLabel: function (recipient) { return recipient.name; }, getItemValue: function (recipient) { return recipient.id; }, bottomAction: {
            label: "+ Add a new recipient",
            onClick: function () {
                navigate("/wires/templates/recipients/new", {
                    state: { from: location.pathname },
                });
            },
        }, error: error }));
};
export default RecipientSelector;
