import React, { useState } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { X } from "react-feather";
import ReactMarkdown from "react-markdown";
import { Button } from "@narmi/design_system";
import { translateStringSetting } from "byzantine/src/l10n/SettingLocalizers";

export default function InstitutionAnnouncement({ announcement, storageId }) {
  const { l10n } = useLocalization();

  const [isHidden, setIsHidden] = useState(
    window.localStorage.getItem(`announcement-${storageId}`)
  );

  const hideAnnouncement = () => {
    setIsHidden(true);
    if (window.localStorage) {
      window.localStorage.setItem(`announcement-${storageId}`, true);
    }
   };

  return (
    <>
      {!isHidden && (
        <div className="announcement-wrapper">
          <div className="announcement-text">
            <ReactMarkdown>
              {translateStringSetting(
                "institution-announcement",
                announcement,
                l10n
              )}
            </ReactMarkdown>
          </div>
          <div className="announcement-close-button">
            <Button kind="plain" label={<X />} onClick={hideAnnouncement} />
          </div>
        </div>
      )}
    </>
  );
}

InstitutionAnnouncement.propTypes = {
  announcement: PropTypes.string.isRequired,
  storageId: PropTypes.string.isRequired,
};
