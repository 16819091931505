var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSlice } from "@reduxjs/toolkit";
// TODO: update when integrating with API and update with correct types
var prepareRecipients = function (recipients) { return ({
    payload: {
        recipients: recipients,
    },
}); };
var initialState = [];
// reducers that update the store's state
var slice = createSlice({
    name: "recipients",
    initialState: initialState,
    reducers: {
        fetchRecipients: {
            prepare: prepareRecipients,
            reducer: function (state, action) {
                state.push.apply(state, __spreadArray([], __read(action.payload.recipients), false));
            },
        },
    },
});
export var actions = slice.actions, reducer = slice.reducer;
export default {
    recipients: slice.reducer,
};
