export var RESERVED_BANKING_WORD_LIST = [
    "ACH",
    "ALM",
    "AMT",
    "APR",
    "APY",
    "ARM",
    "ATM",
    "BSA",
    "CD",
    "CDs",
    "CIP",
    "CLO",
    "CLTV",
    "CRA",
    "CTR",
    "DBA",
    "DDA",
    "EBIT",
    "EBITDA",
    "ECOA",
    "EDC",
    "EFT",
    "EIN",
    "ERISA",
    "FAQ",
    "FAS",
    "FASB",
    "FBAR",
    "FBO",
    "FCRA",
    "FDIC",
    "FEMA",
    "FFIEC",
    "FHA",
    "FHFA",
    "FHLB",
    "FHLMC",
    "FIFO",
    "FMLA",
    "FNMA",
    "FOIA",
    "FRB",
    "FTC",
    "FTP",
    "GAAP",
    "GASB",
    "GLBA",
    "GP",
    "HELOC",
    "HMDA",
    "HTM",
    "HUD",
    "IAT",
    "IO",
    "IOLTA",
    "IRA",
    "IRR",
    "IRS",
    "ITIN",
    "ITM",
    "KYC",
    "LC",
    "LIBOR",
    "LIFO",
    "LOC",
    "LP",
    "LTV",
    "MICR",
    "MLO",
    "MMDA",
    "MOU",
    "MTM",
    "NACHA",
    "NAICS",
    "NAV",
    "NCUA",
    "NIM",
    "NMLS",
    "NSF",
    "OCC",
    "OD",
    "ODP",
    "OFAC",
    "PBGC",
    "PCA",
    "PEP",
    "PO",
    "POS",
    "PSA",
    "PTD",
    "Regulation AA",
    "Reg AA",
    "Regulation B",
    "Reg B",
    "Regulation C",
    "Reg C",
    "Regulation CC",
    "Reg CC",
    "Regulation D",
    "Reg D",
    "Regulation M",
    "Reg M",
    "Regulation P",
    "Reg P",
    "Regulation T",
    "Reg T",
    "Regulation U",
    "Reg U",
    "Regulation X",
    "Reg X",
    "Regulation Z",
    "Reg Z",
    "RDC",
    "ROA",
    "ROE",
    "SAR",
    "SIPC",
    "SEC",
    "SWIFT",
    "TAC",
    "TIPS",
    "SBA",
    "SSN",
    "TIL",
    "TIN",
    "UCC",
    "UCC-1",
    "UCC-2",
    "UCC-3",
    "UCC-4",
    "VAR",
    "WAC",
    "WAL",
    "WAM",
    "WI",
    "YTM",
    "YTC",
    "ZBA",
    "LLC",
];
/* IMPORTANT!
For any false positives or false negatives reported by FIs, please edit the match and non-match lists
on `byzantine/__tests__/utils.test.js`. For a more detailed explanation of the regex, see:
https://regex101.com/r/i6oQfE/1
*/
// Any changes here should also be done on cerulean's AutocompleteStreetAddress.jsx
// case insensitive
export var PO_BOX_REGEX = /\b(?:!?(p\.?\s*[o0]b?\.?)|pos(t|tal)\s+(office|box)|(pobox\s*[0-9]*)|box$|(box\s*?#?[0-9]*$))(\s+)?(?:box|[0-9]*)?\b/i;
