// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".templateCardContainer--uCL7X{display:flex;flex-direction:row;flex:1;flex-basis:100%}@media only screen and (min-width: 768px){.templateCardContainer--uCL7X{max-width:343px}}@media only screen and (min-width: 1280px){.templateCardContainer--uCL7X{max-width:372px}}", "",{"version":3,"sources":["webpack://./src/modules/transfers/wire/templates/TemplatesList/Template/Template.module.scss","webpack://./../scss/_mixins.scss"],"names":[],"mappings":"AAEA,8BACE,YAAA,CACA,kBAAA,CACA,MAAA,CACA,eAAA,CCSA,0CDbF,8BAOI,eAAA,CAAA,CCMF,2CDbF,8BAWI,eAAA,CAAA","sourcesContent":["@import \"../../../../../../../../scss/mixins\";\n\n.templateCardContainer {\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  flex-basis: 100%;\n\n  @include minViewport(\"m\") {\n    max-width: 343px;\n  }\n\n  @include minViewport(\"l\") {\n    max-width: 372px;\n  }\n}\n","@use \"sass:map\";\n@import \"./vars\";\n\n/**\n* Wraps content in a media query that targets the\n* given breakpoint size _and larger_.\n*\n* ```scss\n* @include minViewport(\"m\") {\n*   // styles targeting \"m\" size _and larger_\n* }\n* ```\n*/\n@mixin minViewport($breakpointSize) {\n  $breakpoint: map-get($breakpoints-map, $breakpointSize);\n  @media only screen and (min-width: #{$breakpoint}) {\n    @content;\n  }\n}\n\n/**\n* Wraps content in a media query that targets viewports\n* smaller than the given breakpoint size.\n* (Not inclusive of the given breakpoint)\n*\n* ```scss\n* @include maxViewport(\"m\") {\n*   // styles targeting viewports smaller than \"m\"\n* }\n* ```\n*/\n@mixin maxViewport($breakpointSize) {\n  $breakpoint: map-get($breakpoints-map, $breakpointSize);\n  @media only screen and (max-width: #{$breakpoint - 1px}) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"templateCardContainer": "templateCardContainer--uCL7X"
};
export default ___CSS_LOADER_EXPORT___;
