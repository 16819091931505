import React, { useState } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { Mail, Phone, Send, ArrowRight } from "react-feather";
import Institution from "byzantine/src/Institution";
import { translateSupportContacts } from "byzantine/src/l10n/SettingLocalizers";
import { useLoadingContext } from "cerulean";

function BasicContactUsCard({ title, content, icon }) {
  return (
    <div className="card">
      <div className="contact-us-icon">{icon}</div>
      <div>
        <div className="card-title">{title}</div>
        <div className="contact-us-card-body">{content}</div>
      </div>
    </div>
  );
}
BasicContactUsCard.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.node,
};

function ContactUsCard({ contact, help_email, help_phone }) {
  const { type, title, ...meta } = contact;
  const email = meta.email || help_email;
  const phone = meta.phone || help_phone;

  switch (type) {
    case "MESSAGE_CENTER":
      return (
        <BasicContactUsCard
          icon={<Send size={20} color="white" />}
          title={title}
          content={
            <a className="message-link" href="/messages">
              Message center{" "}
              <ArrowRight className="arrow-right" size={17} strokeWidth={3} />
            </a>
          }
        />
      );
    case "EMAIL":
      if (!email) return null;
      return (
        <BasicContactUsCard
          icon={<Mail size={20} color="white" />}
          title={title}
          content={<a href={`mailto:${email}`}>{email}</a>}
        />
      );
    case "PHONE":
      if (!phone) return null;
      return (
        <BasicContactUsCard
          icon={<Phone size={20} color="white" />}
          title={title}
          content={meta.numbers.map((number, index) => (
            <div key={index}>
              {number.name}: <a href={`tel:${phone}`}>{phone}</a>
            </div>
          ))}
        />
      );
    default:
      return null;
  }
}
ContactUsCard.propTypes = {
  contact: PropTypes.object.isRequired,
  help_email: PropTypes.str,
  help_phone: PropTypes.str,
};

export default function ContactUsList({ contacts }) {
  const { l10n } = useLocalization();
  const [institutionSettings, setInstitutionSettings] = useState({});
  const { setIsLoading } = useLoadingContext();
  const translatedContacts = translateSupportContacts(contacts, l10n);

  const fetchSettings = async () => {
    setIsLoading(true);
    const institution = new Institution();
    const settings = await institution.getSettings();
    setInstitutionSettings(settings);
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <div className="contact-us-list">
      <div className="support-section-title">
       {l10n.getString("heading-support-contact")}
      </div>
      <div className="cards">
        {translatedContacts.map((contact) => (
          <ContactUsCard
            key={`${contact.type}${contact.title}`}
            contact={contact}
            help_email={institutionSettings?.help_email}
            help_phone={institutionSettings?.help_phone}
          />
        ))}
      </div>
    </div>
  );
}
ContactUsList.propTypes = {
  contacts: PropTypes.array.isRequired,
};
