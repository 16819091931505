import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ProgressButtons, Tooltip } from "cerulean";
import { useLocalization } from "@fluent/react";
import HeaderWithSteps from "../../HeaderWithSteps";
import { useSudoContext } from "../../SudoContext";
import { ACH_PAYMENT_REVIEW_ROUTE, ACH_PAYMENT_ROUTE, } from "./ACHPaymentRoutes";
var PaymentScreen = function (_a) {
    var navigateToScreen = _a.navigateToScreen;
    var l10n = useLocalization().l10n;
    var _b = useSudoContext(), establishSudo = _b.establishSudo, totalSteps = _b.totalSteps;
    return (_jsxs("div", { children: [_jsx(HeaderWithSteps, { headerText: l10n.getString("ach-payment-payment-title"), step: 2, totalSteps: totalSteps, icon: _jsx(Tooltip, { text: l10n.getString("ach-payment-payment-title-tooltip"), children: _jsx("i", { className: "narmi-icon-info", style: {
                            fontSize: "var(--font-size-s)",
                        } }) }) }), _jsx("h4", { children: l10n.getString("ach-payment-recipient-title") }), _jsx("h4", { children: l10n.getString("ach-payment-payment-details-title") }), _jsx("h4", { children: l10n.getString("ach-payment-request-title") }), _jsx(ProgressButtons, { backLabel: l10n.getString("button-back"), nextLabel: l10n.getString("button-continue"), onBack: function () { return navigateToScreen("recipient"); }, onNext: function () {
                    return establishSudo("/".concat(ACH_PAYMENT_ROUTE, "/").concat(ACH_PAYMENT_REVIEW_ROUTE));
                } })] }));
};
export default PaymentScreen;
