export var API = {
    getMarketingTarget: function (address, token, unixTimestamp) {
        return {
            // this url is prefixed by dbbl
            url: "promotions/".concat(address, "/?token=").concat(token, "&ts=").concat(unixTimestamp),
            options: {
                method: "GET",
                isJsonResponse: true,
            },
        };
    },
    getMarketingLink: function (address, token, unixTimestamp) {
        // this url is not passed through dbbl so it needs to be manually prefixed with /v1/
        return "/v1/promotions/".concat(address, "/redirect/?token=").concat(token, "&ts=").concat(unixTimestamp);
    },
    getMarketingImage: function (address, token, unixTimestamp) {
        // this url is not passed through dbbl so it needs to be manually prefixed with /v1/
        return "/v1/promotions/".concat(address, "/image/?token=").concat(token, "&ts=").concat(unixTimestamp);
    },
    getMarketingDestination: function (address, token, unixTimestamp) {
        return {
            url: "promotions/".concat(address, "/destination/?token=").concat(token, "&ts=").concat(unixTimestamp),
            options: {
                method: "GET",
                isJsonResponse: true,
            },
        };
    },
    getSegmintIframeUrl: function () { return "/v1/promotions/pingback/"; },
};
