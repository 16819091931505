export var getFluentIdForSECCode = function (secCode) {
    switch (secCode) {
        case "CCD":
            return "ccd-payment-description";
        case "CIE":
            return "cie-payment-description";
        case "CTX":
            return "ctx-payment-description";
        case "IAT":
            return "iat-payment-description";
        case "PPD":
            return "ppd-payment-description";
        case "WEB":
            return "web-payment-description";
        default:
            return "";
    }
};
