var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, memo } from "react";
import { LoadingSkeleton } from "cerulean";
import { entities } from "byzantine";
import Container from "../../Container";
import RecipientScreen from "./RecipientScreen";
import PaymentScreen from "./PaymentScreen";
var ACHPaymentContainer = memo(function () {
    var _a = __read(useState("recipient"), 2), currentScreen = _a[0], setCurrentScreen = _a[1];
    // call all the bootstrap request hooks, specifically `useFetchRecipients` which will populate the store with recipients
    var _b = entities.recipients.bootstrap.useBootstrap(), loading = _b.loading, load = _b.load;
    useEffect(function () {
        load();
    }, [load]);
    var renderContent = function () {
        switch (currentScreen) {
            case "recipient": {
                return _jsx(RecipientScreen, { navigateToScreen: setCurrentScreen });
            }
            case "payment": {
                return _jsx(PaymentScreen, { navigateToScreen: setCurrentScreen });
            }
            default: {
                return _jsx(RecipientScreen, { navigateToScreen: setCurrentScreen });
            }
        }
    };
    return (_jsx(LoadingSkeleton, { isLoading: loading, children: _jsx(Container, { children: renderContent() }) }));
});
export default ACHPaymentContainer;
