import * as Yup from "yup";
import { createForm } from "../../../forms";
var validationSchema = Yup.object().shape({
    recipient: Yup.string().required("Required"),
});
var initialValues = {
    recipient: "",
};
// define form schema and validations
export var ACHPaymentRecipientForm = createForm({
    initialValues: initialValues,
    validationSchema: validationSchema,
});
