var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState, useRef, forwardRef, useEffect } from "react";
import { Button } from "@narmi/design_system";
import { Dropdown, DropdownListItem, SearchBar } from "../Dropdown";
var Highlight = function (_a) {
    var value = _a.value, searchTerm = _a.searchTerm;
    if (!searchTerm) {
        return _jsx(_Fragment, { children: value });
    }
    var regex = new RegExp("(".concat(searchTerm, ")"), "gi");
    var parts = value.split(regex);
    return _jsx(_Fragment, { children: parts.map(function (part, index) { return (regex.test(part) ? _jsx("b", { children: part }, index) : part); }) });
};
var ListItem = forwardRef(function Item(_a, ref) {
    var item = _a.item, value = _a.value, isIndented = _a.isIndented, onChange = _a.onChange, searchTerm = _a.searchTerm, closeDropdown = _a.closeDropdown, getItemLabel = _a.getItemLabel, getItemValue = _a.getItemValue;
    return (_jsx(DropdownListItem, { anyItemSelected: !!value, isSelected: value === getItemValue(item), isIndented: isIndented, closeDropdown: closeDropdown, onClick: function () {
            onChange(getItemValue(item));
        }, ariaLabel: "Item: ".concat(getItemLabel(item)), ref: ref, boldSelected: true, children: _jsx(Highlight, { value: getItemLabel(item), searchTerm: searchTerm }) }));
});
var SearchSelector = function (_a) {
    var value = _a.value, items = _a.items, label = _a.label, onChange = _a.onChange, error = _a.error, filter = _a.filter, getItemLabel = _a.getItemLabel, getItemValue = _a.getItemValue, bottomAction = _a.bottomAction;
    var _b = __read(useState(false), 2), isDropdownOpen = _b[0], setIsDropdownOpen = _b[1];
    var _c = __read(useState(""), 2), searchTerm = _c[0], setSearchTerm = _c[1];
    var _d = __read(useState(-1), 2), indexFocused = _d[0], setIndexFocused = _d[1];
    var listRef = useRef(null);
    var bottomActionRef = useRef(null);
    var searchInputRef = useRef(null);
    useEffect(function () {
        var _a;
        if (isDropdownOpen) {
            (_a = searchInputRef === null || searchInputRef === void 0 ? void 0 : searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isDropdownOpen]);
    var onSearchTermUpdate = function (term) {
        setIndexFocused(-1);
        setSearchTerm(term);
    };
    var filteredItems = items.filter(function (item) { return filter(item, searchTerm); });
    var getListRefMap = useCallback(function () {
        if (!listRef.current) {
            listRef.current = new Map();
        }
        return listRef.current;
    }, [listRef]);
    var closeDropdown = function () { return setIsDropdownOpen(false); };
    var getRefNodeByIndex = useCallback(function (index) {
        var listRefs = getListRefMap();
        if (index >= filteredItems.length && bottomAction) {
            return bottomActionRef.current;
        }
        return listRefs.get(getItemValue(filteredItems[index]));
    }, [getListRefMap, filteredItems, getItemValue]);
    var onKeyDown = useCallback(function (event) {
        var list = getListRefMap();
        var lowestIndex = 0;
        var highestIndex = filteredItems.length - (bottomAction ? 0 : 1);
        switch (event.key) {
            case "Enter": {
                event.preventDefault();
                if (filteredItems[indexFocused]) {
                    onChange(getItemValue(filteredItems[indexFocused]));
                }
                return;
            }
            case "ArrowDown": {
                if (!isDropdownOpen) {
                    setIsDropdownOpen(true);
                }
                setIndexFocused(function (prevIndexFocused) {
                    event.preventDefault();
                    var nextIndex;
                    if (prevIndexFocused + 1 > highestIndex) {
                        nextIndex = lowestIndex;
                    }
                    else {
                        nextIndex = prevIndexFocused + 1;
                    }
                    var listItem = getRefNodeByIndex(nextIndex);
                    listItem === null || listItem === void 0 ? void 0 : listItem.focus();
                    return nextIndex;
                });
                return;
            }
            case "ArrowUp": {
                if (!isDropdownOpen) {
                    setIsDropdownOpen(true);
                }
                event.preventDefault();
                setIndexFocused(function (prevIndexFocused) {
                    var nextIndex;
                    if (prevIndexFocused - 1 < lowestIndex) {
                        nextIndex = highestIndex;
                    }
                    else {
                        nextIndex = prevIndexFocused - 1;
                    }
                    var listItem = getRefNodeByIndex(nextIndex);
                    listItem === null || listItem === void 0 ? void 0 : listItem.focus();
                    return nextIndex;
                });
                return;
            }
            case "tab": {
                var listItem = list.get(getItemValue(filteredItems[indexFocused]));
                listItem === null || listItem === void 0 ? void 0 : listItem.blur();
                setIndexFocused(-1);
                break;
            }
            default:
        }
    }, [
        indexFocused,
        setIndexFocused,
        filteredItems,
        onChange,
        isDropdownOpen,
        getRefNodeByIndex,
        getListRefMap,
        getItemLabel,
        getItemValue,
    ]);
    var itemList = filteredItems.map(function (item) { return (_jsx(ListItem, { item: item, value: value, searchTerm: searchTerm, isIndented: false, closeDropdown: closeDropdown, getItemValue: getItemValue, onChange: onChange, getItemLabel: getItemLabel, ref: function (node) {
            var listRefMap = getListRefMap();
            if (node) {
                listRefMap === null || listRefMap === void 0 ? void 0 : listRefMap.set(getItemValue(item), node);
            }
            else {
                listRefMap === null || listRefMap === void 0 ? void 0 : listRefMap.delete(getItemValue(item));
            }
        } }, getItemValue(item))); });
    var selectedItem = items.find(function (item) { return getItemValue(item) === value; });
    return (_jsx("div", { onKeyDown: onKeyDown, className: "searchSelector", children: _jsxs(Dropdown, { triggerLabel: label, triggerValue: selectedItem ? getItemLabel(selectedItem) : "", defaultOpen: false, ariaLabel: label, keyHandler: function () { }, isOpen: isDropdownOpen, setIsOpen: setIsDropdownOpen, error: error || "", children: [_jsx(SearchBar, { searchTerm: searchTerm, setSearchTerm: onSearchTermUpdate, ref: searchInputRef }), itemList, bottomAction && (_jsx(DropdownListItem, { anyItemSelected: !!value, onClick: bottomAction.onClick, ariaLabel: bottomAction.label, isSelected: false, isIndented: false, closeDropdown: closeDropdown, ref: bottomActionRef, boldSelected: true, children: _jsx(Button, { kind: "plain", label: bottomAction.label }) }))] }) }));
};
export default SearchSelector;
