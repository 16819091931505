var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import { DateTime } from "luxon";
import Address from "./Address";
import ApiHttp from "./ApiHttp";
// eslint-disable-next-line import/no-cycle
import OrganizationUser from "./OrganizationUser";
function coerceToDate(value) {
    if (value instanceof Date)
        return value;
    if (typeof value === "string" && value !== "")
        return new Date(value);
    if (typeof value === "number")
        return new Date(value * 1000);
    return undefined;
}
var User = /** @class */ (function () {
    function User(props) {
        var _a;
        Object.assign(this, props);
        // from ProfileSerializer
        if (!this.id && props.uuid)
            this.id = props.uuid;
        this.updated_at = coerceToDate(props.updated_at);
        this.addresses = ((_a = props.addresses) === null || _a === void 0 ? void 0 : _a.map(function (a) { return new Address(a); })) || [];
        this.phone_numbers = props.phone_numbers || [];
        this.features = props.features || {};
        this.org_uuid = props.org_uuid || props.organization_uuid || null;
        this.org_role = props.org_role || props.role || null;
        this.business_permissions = props.business_permissions || [];
        // additional props set from OrganizationUserSerializer
        if (!this.uuid)
            this.uuid = this.id;
        if (!this.role && props.org_role)
            this.role = props.org_role;
        if (!this.organization_uuid && props.org_uuid)
            this.organization_uuid = props.org_uuid;
        this.phone =
            props.phone ||
                (Array.isArray(props.phone_numbers) ? props.phone_numbers[0] : undefined);
        if (!this.role && props.org_role)
            this.role = props.org_role;
        if (!this.organization_uuid && props.org_uuid)
            this.organization_uuid = props.org_uuid;
        this.last_login = coerceToDate(props.last_login);
    }
    User.prototype.getDescription = function () {
        if (this.first_name || this.last_name) {
            return [this.first_name, this.last_name].filter(function (x) { return x; }).join(" ");
        }
        return this.email;
    };
    User.prototype.hasEstatements = function () {
        return !this.paper_statements;
    };
    User.prototype.getShortDescription = function () {
        return this.first_name || this.last_name || this.username;
    };
    User.prototype.getLastLogin = function () {
        if (this.last_login) {
            return DateTime.fromJSDate(this.last_login).toFormat("M/d/yyyy");
        }
        return "-";
    };
    User.maskEmail = function (email) {
        // fall back to profile email if core provided email does not exist
        if (!email)
            return "";
        var firstLetterInEmail = email[0];
        // if there are no '@' (which should never happen), return the first letter of email with asterisks
        var emailSplitByAtSign = email.split("@");
        if (emailSplitByAtSign.length < 2) {
            return "".concat(firstLetterInEmail, "*****");
        }
        // if there are multiple '@', we just want what is after the last one
        var domain = emailSplitByAtSign.at(-1) || "";
        var firstLetterOfDomain = domain[0];
        // if there are no periods in the domain, return first letter of email and first letter of domain with asterisks
        var domainSplitByPeriod = domain.split(".");
        if (domainSplitByPeriod.length < 2) {
            return "".concat(firstLetterInEmail, "*****@").concat(firstLetterOfDomain, "*****");
        }
        // if there are multiple periods in the domain, we just want what is after the last one
        var topLevelDomain = domainSplitByPeriod.at(-1) || "";
        return "".concat(firstLetterInEmail, "*****@").concat(firstLetterOfDomain, "*****.").concat(topLevelDomain);
    };
    User.prototype.isBusinessAccountHolder = function () {
        return this.org_role === OrganizationUser.ROLE.ACCOUNT_HOLDER;
    };
    User.prototype.isBusinessAdmin = function () {
        return (this.org_role === OrganizationUser.ROLE.ADMIN ||
            this.isBusinessAccountHolder());
    };
    User.prototype.isPersonalUserOrAccountHolder = function () {
        return !this.isBusiness() || this.isBusinessAccountHolder();
    };
    User.prototype.isPersonalUserOrBusinessAdmin = function () {
        return !this.isBusiness() || this.isBusinessAdmin();
    };
    User.prototype.getEnrollmentCodeEmail = function () {
        // if the enrolling user is a personal user or an account holder, show the masked core_provided_email
        // if the enrolling user is a business user but isn't the account holder, the invitation email was sent to their normal user email (not the core_provided_email)
        var email = this.isPersonalUserOrAccountHolder()
            ? this.core_provided_email
            : this.email;
        return User.maskEmail(email);
    };
    User.prototype.getMaskedEmail = function () {
        return User.maskEmail(this.core_provided_email || this.email);
    };
    User.prototype.resendEnrollmentCode = function () {
        return ApiHttp.fetch("users/".concat(this.uuid, "/enrollment_code"), {
            method: "POST",
        });
    };
    User.prototype.updateUsername = function (newUsername) {
        if (!newUsername)
            return Promise.resolve(this);
        return ApiHttp.fetch("users/".concat(this.uuid, "/username"), { method: "POST" }, { new_username: newUsername }).then(function (response) { return User.deserialize(response.user); });
    };
    User.prototype.updatePhoneNumber = function (newPhoneNumber) {
        if (!newPhoneNumber)
            return Promise.resolve(this);
        return ApiHttp.fetch("users/".concat(this.uuid, "/phone"), { method: "PUT" }, { number: newPhoneNumber }).then(function (response) { return User.deserialize(response.user); });
    };
    User.prototype.updatePassword = function (newPassword1, newPassword2, oldPassword) {
        if (!newPassword1 || !newPassword2)
            return Promise.resolve(this);
        return ApiHttp.fetch("users/".concat(this.uuid, "/password"), { method: "POST" }, __assign({ new_password1: newPassword1, new_password2: newPassword2 }, (oldPassword ? { old_password: oldPassword } : null))).then(function (response) { return ({
            message: response.message,
            user: User.deserialize(response.user),
        }); });
    };
    User.prototype.updateEmail = function (newEmail) {
        if (!newEmail)
            return Promise.resolve(this);
        return ApiHttp.fetch("users/".concat(this.uuid, "/emails"), { method: "POST" }, { new_email: newEmail });
    };
    User.prototype.updateAddress = function (address) {
        if (!address)
            return Promise.resolve(this);
        return ApiHttp.fetch("users/".concat(this.uuid, "/address"), { method: "PUT" }, address).then(function (response) { return User.deserialize(response.user); });
    };
    User.prototype.getPhoneDevices = function () {
        return ApiHttp.fetch("users/".concat(this.uuid, "/mfa_devices"), {
            method: "GET",
        }).then(function (response) { return response.phone_devices; });
    };
    User.prototype.addMfaDevice = function (payload) {
        return ApiHttp.fetch("users/".concat(this.uuid, "/mfa_devices"), { method: "POST" }, payload);
    };
    User.prototype.sendEnrollmentCompletedRequest = function () {
        return ApiHttp.fetch("users/".concat(this.uuid, "/enrollment_complete"), { method: "POST" }, { has_estatements: this.hasEstatements() });
    };
    User.prototype.isBusiness = function () {
        return !!this.organization_uuid;
    };
    User.prototype.getFundingCards = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ApiHttp.fetch("users/".concat(this.uuid, "/funding"), {
                            method: "GET",
                        })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, (response === null || response === void 0 ? void 0 : response.funding_methods) ? response.funding_methods : []];
                }
            });
        });
    };
    User.prototype.addFundingCard = function (payload, token, secret) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ApiHttp.fetch("users/".concat(this.uuid, "/funding"), { method: "POST", token: token, secret: secret }, payload)];
                    case 1:
                        response = _a.sent();
                        if (!(response === null || response === void 0 ? void 0 : response.funding_method))
                            throw Error("Unable to use newly added card.");
                        return [2 /*return*/, response.funding_method];
                }
            });
        });
    };
    User.prototype.makeLoanPayment = function (payload) {
        return ApiHttp.fetch("users/".concat(this.uuid, "/payment"), { method: "POST" }, payload);
    };
    User.enroll = function (payload) {
        return ApiHttp.fetch("enroll", { method: "POST" }, payload).then(function (response) { return User.deserialize(response.user); });
    };
    User.getUser = function () {
        return ApiHttp.fetch("me")
            .then(function (response) { var _a; return User.deserialize((_a = response.users) === null || _a === void 0 ? void 0 : _a[0]); })
            .catch(function () { return null; });
    };
    User.verifyEnrollmentCode = function (payload) {
        return ApiHttp.fetch("enroll_verify", { method: "POST" }, payload).then(function (response) {
            return response.user
                ? User.deserialize(response.user)
                : // eslint-disable-next-line prefer-promise-reject-errors
                    Promise.reject("Could not verify your enrollment code. Please try again.");
        });
    };
    User.deserialize = function (payload) {
        if (!payload)
            return null;
        return new User(__assign(__assign({}, payload), { addresses: payload.addresses && payload.addresses.map(function (a) { return a; }) }));
    };
    User.prototype.getPrimaryAddress = function () {
        return this.addresses.find(function (a) { return a.isPrimary(); });
    };
    User.prototype.getMailingAddress = function () {
        return this.addresses.find(function (a) { return a.isMailing(); });
    };
    User.PERSONAL = "personal";
    User.BUSINESS = "business";
    return User;
}());
export default User;
