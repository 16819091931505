var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var Address = /** @class */ (function () {
    function Address(props) {
        if (props === void 0) { props = {}; }
        Object.assign(this, __assign({ street_address: "", street_address_2: "", city: "", region_code: "", postal_code: "", country_code: Address.COUNTRIES.US }, props));
    }
    Address.prototype.toString = function () {
        var fullStreetAddress = "";
        if (this.street_address) {
            fullStreetAddress = this.street_address_2
                ? "".concat(this.street_address, " ").concat(this.street_address_2, ",")
                : "".concat(this.street_address, ",");
        }
        // eslint-disable-next-line no-return-assign
        return [
            fullStreetAddress,
            this.city ? "".concat(this.city, ",") : "",
            this.region_code,
            this.postal_code,
            // ToDo: figure out what this is trying to do and do it some other way
            // when country_code != street_address, lets 'Same as
            // physical/residential address' display for Mailing Address, rather than US.
            (this.country_code = this.street_address ? this.country_code : ""),
        ]
            .filter(function (a) { return a; })
            .join(" ");
    };
    Address.prototype.validate = function () {
        var _this = this;
        var requiredAddressFields = [
            "street_address",
            "city",
            "region_code",
            "postal_code",
            "country_code",
        ];
        requiredAddressFields.forEach(function (field) {
            if (!_this[field])
                throw new Error("Address field ".concat(field, " is required"));
        });
    };
    Address.prototype.isPrimary = function () {
        return this.type === Address.PRIMARY;
    };
    Address.prototype.isMailing = function () {
        return this.type === Address.MAILING;
    };
    Address.PRIMARY = "primary";
    Address.MAILING = "mailing";
    Address.COUNTRIES = {
        US: "US",
    };
    return Address;
}());
export function updateAddress(previous, update) {
    if (previous === void 0) { previous = new Address(); }
    if (update === void 0) { update = new Address(); }
    if (update === null)
        return null;
    return new Address(__assign(__assign({}, (previous || new Address())), update));
}
export default Address;
