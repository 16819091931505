import ApiHttp from "./ApiHttp";
import utils from "./utils";
import Account from "./Account";

class M2M {
  constructor(props) {
    this.amount = props.amount;
    this.from_account = props.from_account_id;
    this.accounts = props.accounts || [];
    this.relationship = props.relationship;
    if (props.to_member_id) {
      this.to_member_id = props.to_member_id;
      this.memo = props.memo ? props.memo : "";
    } else {
      this.to_account_number = props.to_account_number;
      this.last_name = props.last_name;
      this.first_name = props.first_name;
    }
    this.itempotencyKey = props.itempotencyKey;
  }

  existingMemberFriendPayload() {
    return {
      amount: utils.dollarsToPennies(this.amount),
      from_account: this.from_account,
      friend: this.to_member_id,
      memo: this.memo,
      relationship: this.relationship,
    };
  }

  newMemberFriendPayload() {
    return {
      amount: utils.dollarsToPennies(this.amount),
      from_account: this.from_account,
      to_account_number: this.to_account_number,
      beneficiary_name: this.last_name,
      relationship: this.relationship,
    };
  }

  validateTransferAmountDoesNotExceedAvailableBalance() {
    const account = Account.getAccountFromId(this.from_account, this.accounts);
    if (!account) {
      return "Please specify a source account";
    }
    if (this.amount > account.transferableBalanceAsFloat()) {
      return "Transfer amount cannot exceed source account's available balance";
    }
    return null;
  }

  submit() {
    const headers = this.itempotencyKey
      ? { "Idempotency-Key": this.itempotencyKey }
      : {};
    return ApiHttp.fetch(
      "m2m",
      { method: "POST", headers },
      this.existingMemberFriendPayload(),
    );
  }

  addNewMemberFriend() {
    return ApiHttp.fetch(
      "m2m",
      { method: "POST" },
      this.newMemberFriendPayload(),
    );
  }
}

export default M2M;
