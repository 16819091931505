import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes } from "react-router-dom";
import { ComposeProviders, entities } from "byzantine";
import Container from "../../Container";
import ACHPaymentContainer from "./ACHPaymentContainer";
import ReviewScreen from "./ReviewScreen";
import { SudoProvider, SudoRoutes } from "../../SudoContext";
import RequestOTP from "../../SudoContext/RequestOTP";
import SubmitOTP from "../../SudoContext/SubmitOTP";
// corresponding to routes in indigo/urls.py
export var ACH_PAYMENT_ROUTE = "ach_payment";
export var ACH_PAYMENT_REVIEW_ROUTE = "review";
// have to bootstrap recipient.bootstrap.Provider so we can call the `useFetchRecipients` hook in `ACHPaymentContainer`
// `useFetchRecipients` is defined in the bootstrap request hooks
// also bootstrap the form provider so we can use formik
var Providers = [
    entities.recipients.bootstrap.Provider,
    entities.recipients.ACHPaymentDetailForm.Provider,
    entities.recipients.ACHPaymentRecipientForm.Provider,
];
var ACHPaymentRoutes = function () { return (_jsx(ComposeProviders, { components: Providers, children: _jsx(Container, { children: _jsx(SudoProvider, { basename: ACH_PAYMENT_ROUTE, children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(ACHPaymentContainer, {}) }), _jsx(Route, { path: ACH_PAYMENT_REVIEW_ROUTE, element: _jsx(ReviewScreen, {}) }), _jsx(Route, { path: "".concat(SudoRoutes.RequestOTP), element: _jsx(RequestOTP, {}) }), _jsx(Route, { path: "".concat(SudoRoutes.SubmitOTP), element: _jsx(SubmitOTP, {}) })] }) }) }) })); };
export default ACHPaymentRoutes;
