import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "cerulean";
import { entities, modules } from "byzantine";
import Address from "../../settings/profile_settings/UserSettingsProfilePage/Address";
import styles from "./RecipientCard.module.scss";
var RecipientCard = function (_a) {
    var recipientId = _a.recipientId, onDelete = _a.onDelete;
    var recipient = entities.wires.useOneWireRecipient(recipientId);
    if (!recipient)
        return null;
    var destinationAccountPreview = modules.wireTemplates.getDestinationAccountPreview(recipient);
    return (_jsxs("div", { className: styles.selectedRecipient, children: [_jsxs("div", { className: styles.selectedRecipientTopContainer, children: [_jsx("div", { className: styles.recipientName, children: recipient.name }), _jsx("div", { className: styles.recipientActions, children: _jsx(Button, { kind: "plain", size: "s", onClick: onDelete, children: "Remove" }, "delete-recipient") })] }), _jsx("div", { className: styles.recipientAddress, children: _jsx(Address, { address: recipient.address }) }), _jsxs("div", { className: styles.templateInstitution, children: [_jsx("div", { className: styles.institutionLogo, children: _jsx("span", { className: "narmi-icon-bank" }) }), _jsx("p", { children: destinationAccountPreview })] })] }));
};
export default RecipientCard;
