import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { ProgressButtons } from "cerulean";
import { useLocalization } from "@fluent/react";
import HeaderWithSteps from "../../HeaderWithSteps";
import { useSudoContext } from "../../SudoContext";
import { ACH_PAYMENT_ROUTE } from "./ACHPaymentRoutes";
var ReviewScreen = function () {
    var l10n = useLocalization().l10n;
    var navigate = useNavigate();
    var totalSteps = useSudoContext().totalSteps;
    return (_jsxs("div", { children: [_jsx(HeaderWithSteps, { headerText: l10n.getString("ach-payment-review-title"), step: totalSteps, totalSteps: totalSteps }), _jsx(ProgressButtons, { backLabel: l10n.getString("button-back"), nextLabel: l10n.getString("ach-payment-review-action"), onBack: function () {
                    navigate("/".concat(ACH_PAYMENT_ROUTE));
                }, onNext: navigate("/") })] }));
};
export default ReviewScreen;
