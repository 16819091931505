var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useObjectMemo } from "../../../hooks";
import { useRequestWithFeedback, } from "../../../composites";
import { API } from "../wires/api";
import { actions } from "./slice";
import RECIPIENTS_DUMMY_DATA from "./dummyData";
export var useFetchRecipients = function () {
    var dispatch = useDispatch();
    // TODO: update with real API
    var _a = useRequestWithFeedback(), send = _a.send, loading = _a.loading, status = _a.status;
    var fetchRecipients = useCallback(function (overrideRunProps) {
        if (overrideRunProps === void 0) { overrideRunProps = {}; }
        send(__assign({ 
            // TODO: update with real API
            action: API.listWireRecipients(), onData: function () {
                dispatch(actions.fetchRecipients(RECIPIENTS_DUMMY_DATA));
            } }, overrideRunProps));
    }, [dispatch, send]);
    // when creating a hook that has an API request, has to follow the below convention
    return useObjectMemo({
        send: fetchRecipients,
        loading: loading,
        error: status === "error",
        hasData: true,
    });
};
