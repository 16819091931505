import { ACCOUNT_TYPE_OPTIONS } from "./consts";
var RECIPIENTS_DUMMY_DATA = [
    {
        id: "bts-1",
        name: "rap mon",
        ach_destination: {
            bank_name: "bank of rap mon",
            account_type: ACCOUNT_TYPE_OPTIONS.BUSINESS_CHECKING,
            account_number: "111111111",
            routing_number: "222222222",
        },
        fedwire_destination: null,
    },
    {
        id: "bts-2",
        name: "jin",
        ach_destination: null,
        fedwire_destination: {
            bank_name: "bank of jin",
            account_type: ACCOUNT_TYPE_OPTIONS.BUSINESS_SAVINGS,
            account_number: "333333333",
            routing_number: "444444444",
        },
    },
    {
        id: "bts-3",
        name: "jimin",
        ach_destination: null,
        fedwire_destination: {
            bank_name: "bank of jimin",
            account_type: ACCOUNT_TYPE_OPTIONS.PERSONAL_CHECKING,
            account_number: "555555555",
            routing_number: "666666666",
        },
    },
];
export default RECIPIENTS_DUMMY_DATA;
